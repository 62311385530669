<script setup lang="ts"></script>

<template>
  <main class="fullscreen">
    <div class="default-layout">
      <slot />
    </div>
  </main>
</template>
<style lang="scss">
.fullscreen {
  padding-top: 20px;
}
</style>
